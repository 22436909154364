import React from 'react'
import ClientCard from 'components/client-card'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'
import { avy_1, avy_2, avy_3, avy_4, avy_5, woodwatch_1, woodwatch_2, woodwatch_3, bibbr_1, bibbr_2, bibbr_3, yummygum_2 } from 'images/klanten'
import { avy_logo, bibbr_logo } from 'images/klanten'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

const dummyKlantVerhaal = {
  pageTitle: '“Ik kan onze salarisadministratie nu doen binnen 10 minuten”',
  pageStreamer: 'Avy te Amsterdam, Interview met Ilse van Nierop',
  useCase: '35 werknemers',
  person: {
    name: 'Ilse van Nierop',
    functionTitle: 'HR-manager',
    companyLogo: 'avy_logo',
    description: 'Sinds 2016 bouwt Avy elektrische luchtvaartuigen met de flexibiliteit van een drone en de efficiëntie van een vliegtuig.',
    portraitImage: avy_1,
    portraitImageSmall: avy_2,
    link: 'https://www.avy.eu/',
    internallink: false,
    btnText: 'Bekijk de website&nbsp;→'
  },
  content: [{
    title: 'Bedrijf',
    description: 'Sinds 2016 bouwt Avy elektrische luchtvaartuigen met de flexibiliteit van een drone en de efficiëntie van een vliegtuig. Gemaakt voor lange afstandsvluchten waardoor deze uitstekend ingezet kunnen worden voor humanitaire doeleinden. Denk hierbij aan “wildlife conservation” en medisch transport van bloedsamples, vaccins en medicijnen.'
    }, {
      title: 'Personeel',
      description: 'Ilse van Nierop: “We zijn in drie jaar tijd gegroeid van 2 naar ruim 30 medewerkers en we verwachten telkens verder uit te breiden. Wij geven medewerkers veel vrijheid, waarschijnlijk meer dan andere bedrijven. Zo bepalen medewerkers zelf hun werktijden en het aantal verlofdagen. Wij geloven dat dit ten goede komt aan de verantwoordelijkheid en betrokkenheid van het personeel”.'
    }, {
      image: avy_4
    }, {
      title: 'Waarom gekozen voor Employes?',
      description: 'Al vanaf het begin werkt Avy met Employes. Ilse van Nierop: “We zijn met Employes begonnen omdat allereerst de prijs écht scherp is. Maar daarnaast is het ook fijn dat je de verloning zelf in de hand hebt.”<br/><br/> Hoe kijk je naar de alternatieven, zoals payrolling of het uitbesteden aan een salarisadministrateur? Ilse van Nierop: “Ik geloof niet zo in de toekomst van Payrollbedrijven. Payrollers doen nog veel zaken handmatig, dit is tijdrovend en bovenal foutgevoelig. Overigens kan ik me ook niet meer indenken dat ik nog met een externe salarisadministrateur zou moeten werken, zeker met het aanleveren van mutaties. Wel kan ik me voorstellen dat er partijen blijven die complexe HR en salaris vraagstukken behandelen.”'
    },{
      quote: '“Eenvoudige tool die HR leuk en overzichtelijk maakt.”'
    }, {
      image: avy_3
    },
    {
      title: 'Ben je blij met die keuze?',
      description: '“Absoluut. Ik heb niet eerder met zo&rsquo;n fijn systeem gewerkt als Employes. Ik kan zelf de uren invullen, een medewerker aanmaken en wijzigen doorvoeren zonder hiervoor iemand te hoeven inschakelen. Dat is echt heel fijn! Ik besteed momenteel nog geen 10 minuten aan de salarisadministratie.” zegt ze met een grijns.<br/><br/>Tot slot kregen we nog wel één advies van Ilse; “Blijf zo toegankelijk en bereikbaar als jullie momenteel zijn, ook wanneer jullie doorgroeien”. Dat advies nemen we ter harte.'
}],
  otherClientStories: [{
    name: 'Ruben Paijens',
    functionTitle: 'Mede-oprichter, Bibbr',
    companyLogo: '',
    description: 'Ik vind het vooral fijn dat jullie betrokken zijn.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: bibbr_2,
    quote: 'Ik vind het vooral fijn dat jullie betrokken zijn en makkelijk te bereiken zijn.',
    link: '/klanten/bibbr/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Vince Schwidder',
    functionTitle: 'Mede-oprichter, Yummygum',
    companyLogo: '',
    description: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: yummygum_2,
    quote: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    link: '/klanten/yummygum/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    description: 'Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→'
  }]
}


const klantverhaalTemplate = ({location}) => (
  <Layout location={location}>
    <main className="clientstory animated fadeInPage">

      <Helmet>
        <title>Interview met Ilse van Nierop van Avy | Employes</title>
        <meta name="description" content="Met ruim 14 medewerkers maakt Avy uit Amsterdam elektrische luchtvaartuigen. Ilse aan het woord over hun salarisadministratie." />
        <meta itemprop="name" content="Interview met Ilse van Nierop van Avy | Employes" />
        <meta itemprop="description" content="Met ruim 14 medewerkers maakt Avy uit Amsterdam elektrische luchtvaartuigen. Ilse aan het woord over hun salarisadministratie." />
        <meta itemprop="image" content="https://employes.nl/static/meta-avy.jpg" />

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://employes.nl/klanten/avy/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Interview met Ilse van Nierop van Avy | Employes" />
        <meta property="og:description" content="Met ruim 14 medewerkers maakt Avy uit Amsterdam elektrische luchtvaartuigen. Ilse aan het woord over hun salarisadministratie." />
        <meta property="og:image" content="https://employes.nl/static/meta-avy.jpg" />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Interview met Ilse van Nierop van Avy | Employes" />
        <meta name="twitter:description" content="Met ruim 14 medewerkers maakt Avy uit Amsterdam elektrische luchtvaartuigen. Ilse aan het woord over hun salarisadministratie." />
        <meta name="twitter:image" content="https://employes.nl/static/meta-avy.jpg" />


      </Helmet>


      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid between align-middle">
            <div className="col-6 no-pad">
              <h2>{dummyKlantVerhaal.pageTitle}</h2>
              <p className="streamer large margin-l-bottom">{dummyKlantVerhaal.pageStreamer}</p>
              <span className="badge green">Bedrijfsgrootte: <b>{dummyKlantVerhaal.useCase}</b></span>
            </div>
            <ClientCard className="col-6 no-pad" person={dummyKlantVerhaal.person} isSmall={(window.innerWidth < 768)} />
          </div>
        </div>
      </header>
      <section className="up">
        <div className="clientstory-content padding-xl">
          {dummyKlantVerhaal.content.map((section, idx) => {
            const firstObjectKey = Object.keys(section)[0]
            const isImage = (firstObjectKey === 'image')
            const { title, description } = section

            return (
              <div className="paragraph margin-m-bottom" key={idx}>
                <div className={`container-${isImage ? 'md image' : 'sm'}`} key={idx}>
                  <div className="grid yg center">
                    <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                      {{
                        title: (
                          <div className="text">
                            <h4>{title}</h4>
                            <p className="large" dangerouslySetInnerHTML={{ __html: description }}></p>
                          </div>
                        ),
                        quote: (
                          <div className="client-quote">
                            “Ik kan zelf de uren invullen, een medewerker aanmaken en wijzigen doorvoeren. Dat is echt heel fijn!”
                        </div>
                        ),
                        image: (
                          <img src={section.image} alt="Ilse van Nierop" />
                        )
                      }[firstObjectKey]}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          }
        </div>

        <div className="clientstory-others padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center margin-l-bottom">
              <div className="col-12">
                <h6 className="eyebrow">Ervaringen</h6>
                <h2 className="no-margin">Andere klantverhalen</h2>
              </div>
            </div>
            <div className="grid yg">
              {dummyKlantVerhaal.otherClientStories.map((otherclient, idx) => {
                const { name, functionTitle, companyLogo, portraitImage, quote } = otherclient

                return (
                  <div className="col-4" key={otherclient.name} key={idx}>
                    <ClientCard person={otherclient} isSmall={true}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </section>
    </main>
  </Layout>
)

export default klantverhaalTemplate
