import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"

const ClientCard = (props, ) => {
const { className, isSmall, person: { name, functionTitle, portraitImageSmall, quote, description, portraitImage, link, website, internallink, btnText }} = props



  return (
    <div className={`client-card ${className} ${isSmall ? 'small-card' : 'normal-card'}`}>
      { !isSmall ?
        <div className="client-card-content">
          <div className="client-card-content-image">
          <img src={portraitImage} alt={ name }/>
          </div>


          <div className="client-card-content-text">
            <div>
              <div className="client-name padding-s-bottom">
                <h3>{ name }</h3>
                <p className="streamer small margin-xs-bottom">{ functionTitle }</p>
              </div>

              <div className="client-personal-story padding-m-bottom">
                <p>"{ description }"</p>
              </div>
            </div>

            { internallink ? <Link to={link} className="btn secondary"><span dangerouslySetInnerHTML={ { __html: btnText } }></span></Link> : <a className="btn secondary" target="_blank" href={link}><span dangerouslySetInnerHTML={ { __html: btnText } }></span></a>}

          </div>
        </div>
        :
        <div className="single-other">
          <div className="single-other-image margin-s-bottom">
            <img src={portraitImageSmall} alt={ name }/>
          </div>

          <div className="single-other-content">
            <div>
              <h3>{ name }</h3>
              <p className="streamer small margin-xs-bottom">{ functionTitle }</p>
              <div className="margin-m-bottom client-quote">"{ quote }"</div>
            </div>
            { internallink ? <Link to={link} className="btn secondary"><span dangerouslySetInnerHTML={ { __html: btnText } }></span></Link> : <a className="btn secondary" target="_blank" href={link}><span dangerouslySetInnerHTML={ { __html: btnText } }></span></a>}

          </div>
        </div>
      }
    </div>
  )
}
export default ClientCard
